<template>
	<div class="sameWidth">
		<el-breadcrumb separator-class="el-icon-arrow-right" style="padding:20px 0px 20px 0px;">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/police' }">政策法规</el-breadcrumb-item>
			<el-breadcrumb-item>列表</el-breadcrumb-item>
		</el-breadcrumb>
		<el-form ref="form" :model="form" label-width="80px" :inline="true" size="small">
			<el-form-item :label="emptyFill('标题',2)">
				<el-input style="width:586px;margin-right: 3px;" placeholder="请输入标题" v-model="form.title" clearable>
				</el-input>
			</el-form-item>
			<el-form-item label="颁布日期">
				<el-date-picker v-model="form.issue_dates" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期"  clearable>
				</el-date-picker>
			</el-form-item>
			<el-form-item label="颁布单位">
				<el-select v-model="form.issue_company" placeholder="请选择" clearable style="width: 180px;">
					<el-option label="全部" value=""></el-option>
					<el-option :label="item.name" :value="item.id" v-for="(item,index) in issue_companys" :key="index"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="条法类别">
				<el-select v-model="form.law_type" placeholder="请选择">
					<el-option-group v-for="group in law_type_options" :key="group.key" :label="group.label">
						<el-option v-for="item in group.options" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-option-group>
				</el-select>
			</el-form-item>
			<el-form-item label="法规层次">
				<el-select v-model="form.law_level" placeholder="请选择" clearable style="width: 180px;">
					<el-option label="全部" value=""></el-option>
					<el-option label="财政部规章" value="财政部规章"></el-option>
					<el-option label="财政部规范性文件" value="财政部规范性文件"></el-option>
					<el-option label="国务院文件" value="国务院文件"></el-option>
					<el-option label="其他部委文件" value="其他部委文件"></el-option>
					<el-option label="采购中心文件" value="采购中心文件"></el-option>
					<el-option label="国家法规" value="国家法规"></el-option>
					<el-option label="地方规章办法" value="地方规章办法"></el-option>
					<el-option label="国际法规" value="国际法规"></el-option>
				</el-select>
			</el-form-item>
			
			<el-form-item label="时 效 性" >
				<el-select v-model="form.law_valid" placeholder="请选择" clearable style="width: 150px;">
					<el-option label="全部" value=""></el-option>
					<el-option label="有效" value="有效"></el-option>
					<el-option label="失效" value="失效"></el-option>
					<el-option label="废止" value="废止"></el-option>
				</el-select>
			</el-form-item>
			<el-button type="primary" size="small" style="margin-right: 0;background-color:#cf1322;border: #cf1322 1px solid;"
				@click="form.page = 1;search()">搜索
			</el-button>
		</el-form>
		<el-table :data="tableData" style="width:100%" border size="small">
			<el-table-column prop="title" label="标题" width="590"></el-table-column>
			<el-table-column prop="code" label="发文字号" width="140"></el-table-column>
			<el-table-column prop="issue_date" label="颁布日期" width="90">
				<template slot-scope="scope">
					<span v-if="scope.row.issue_date != '0000-00-00'">{{ scope.row.issue_date}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="implement_date" label="实施日期" width="90">
				<template slot-scope="scope">
					<span v-if="scope.row.implement_date != '0000-00-00'">{{ scope.row.implement_date}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="issue_company" label="颁布单位" width="110">
				<template slot-scope="scope">
					<span v-if="scope.row.issue_company">{{ issue_companys[scope.row.issue_company- 1].name }}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="danger" style="background-color:#cf1322;" size="mini" @click="goTo(scope)">查看详情
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<p style="margin-top: 15px;">
			<el-pagination @current-change="handleCurrentChange" :current-page="form.page" :page-size="10"
				layout="total,  prev, pager, next, jumper" :total="tableCount" style="margin-top: 10px;">
			</el-pagination>
		</p>

	</div>
</template>
<script>
	export default {
		name: 'policeList',
		data() {
			return {
				law_type_options:this.law_type_options,
				form: {
					title:'',
					issue_dates: [],
					issue_company:'',
					law_level: '',
					law_type:'',
					law_valid:'',
					page: 1,
					pageSize: 10,
				},
				tableData: [],
				tableCount: 0,
			}
		},
		mounted() {
			this.form.law_level = this.$route.query.law_level;
			this.form.law_type = this.$route.query.law_type;
			this.form.title = this.$route.query.title;
			this.form.issue_dates = [this.$route.query.start_date,this.$route.query.end_date];
			this.form.issue_company = this.$route.query.issue_company;
			this.form.law_valid = this.$route.query.law_valid;
			this.search();
		},
		methods: {
			emptyFill(label,num){
				if(num == 2){
					label = '标'+'\xa0\xa0\xa0\xa0\xa0\xa0\xa0'+'题';
				}
				return label;
			},
			search() {
				var that = this;
				this.$axios.post(this.baseUrl + 'index/pdicies/pdiciesList', this.form).then(response => {
					that.tableData = response.data.list;
					that.tableCount = response.data.count;
				}).catch(function(error) {
					window.console.log(error)
				}); //ajax请求失败
			},
			handleCurrentChange(val) {
				this.form.page = val;
				this.search();
			}, //翻页
			goTo(scope) {
				this.$router.push({
					path: '/policeContent',
					query: {
						id: scope.row.id
					}
				})
			},
		}
	}
</script>

<style scoped>
	.area,
	.common {
		font-size: 14px;
		text-align: left;
	}

	.area span,
	.common span {
		margin-right: 10px;
		margin-top: 10px;
		padding: 1px 3px;
	}

	.common span {
		margin-right: 10px;
		cursor: pointer;
	}

	.area td,
	.common td {
		vertical-align: top;
	}

	.tableInfo {
		border-collapse: collapse;
		font-size: 14px;
		width: 100%;
	}

	.tableInfo td,
	.tableInfo th {
		padding: 10px;
		border: #DCDFE6 1px solid;
	}

	.tableInfo th {
		background-color: #F2F6FC;
	}
</style>
